import React from 'react';
import { render } from 'react-dom'
import FastClick from 'fastclick'
// import Route from './router'
//
import {Provider} from 'react-redux'
import {AppContainer} from 'react-hot-loader'
import store from './store'

// 多语言配置
import intl from 'react-intl-universal';
import enUS from 'antd-mobile/lib/locale-provider/en_US';
import LocaleProvider from 'antd-mobile/lib/locale-provider';

import locales from './locales/index';
import storage from './utils/storageUtils';
// 全局配置变量
import {
  LANG_SUPPORT,
  LANG_TYPE_DEFAULT,
  LANG_STORAGE_DEFAULT,
  HOME_SHOP_ALIAS_KEY_DEFAULT,
  CURRENCY_STORAGE_DEFAULT
} from './config/constants';
// 入口组件
import App from './pages/App';
import * as serviceWorker from './serviceWorker'
// rem 定义
import './config/rem'
import './styles/base.scss'
import 'mapbox-gl/dist/mapbox-gl.css';

// shopalias 存起来
let shopalias = window.location.hostname.split('.')[0]
if (shopalias === 'www') {
    shopalias = 'one';
}
storage.setItem(HOME_SHOP_ALIAS_KEY_DEFAULT, shopalias);
// 解决ios点击 300毫秒延迟
FastClick.attach(document.body)

const renderApp = (storageLangType) => render(
    <LocaleProvider locale={storageLangType !== 'zh-TW' ? enUS : undefined}>
        <Provider store={store}>
            <AppContainer>
                <App />
            </AppContainer>
        </Provider>
    </LocaleProvider>,
    document.getElementById('root')
)

const runApp = (statusFlag) => {
    renderApp(statusFlag);
    if (module.hot) {
        module.hot.accept();
    }
};

/**
 * 整个工程 locale 初始化配置
 * 1. 取storage中语言设置，没有走第二步
 * 2. 获取当前系统语言
 */
const loadLocales = () => {
    let storageLangType = storage.getItem(LANG_STORAGE_DEFAULT.lang_type);
    if (!storageLangType) {
        const navigatorLangType = window.navigator.userLanguage || window.navigator.systemLanguage || window.navigator.browserLanguage || window.navigator.language;
        // 如果从 当前系统 中获取的语言跟 APP设置的默认值不一样（constants.LOCALES_SUPPORT，这里的值，需要重新设置默认值
        const locales_support_array = LANG_SUPPORT || [];
        for (const item of locales_support_array) {
            if (item && item.value === navigatorLangType) {
                storageLangType = navigatorLangType;
                storage.setItem(LANG_STORAGE_DEFAULT.lang_type, storageLangType);
                break;
            }
        }
        if (!storageLangType) {
            storageLangType = LANG_TYPE_DEFAULT;
            storage.setItem(LANG_STORAGE_DEFAULT.lang_type, storageLangType);
        }
    }
    // 国际化初始化开始
    intl.init({
        currentLocale: storageLangType,
        locales,
    }).then(() => {
        runApp(storageLangType);
    });
};

let storageCurrencyType = storage.getItem(CURRENCY_STORAGE_DEFAULT.currency_type);
if (!storageCurrencyType) {
  storage.setItem(CURRENCY_STORAGE_DEFAULT.currency_type, window.Currency);
}
// 执行国际化初始化，成功之后加载整个APP
loadLocales();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
